<template>
  <div class="home p-3 pt-4 mx-auto">
    <div class="search-bar mx-auto">
      <div class="input-group mb-1 col-12 md-col-6">
        <input
          class="form-control"
          :class="{'has-content': value}"
          ref="searchInput"
          type="text"
          autocapitalize="none"
          autocorrect="off"
          autocomplete="off"
          name="search"
          id="search"
          :placeholder="placeholder"
          style="font-size: 0.75rem;"
          @focus="increaseFontSize"
          @blur="decreaseFontSize"
          v-model="value"
        >
        <span v-if="value" class="clear-button" @click.prevent="clearValue">
          <i class="bi bi-x-circle"/>
        </span>
        <span v-if="value" class="more-info-button" @click.prevent="goToWikipedia">
          <i class="bi bi-wikipedia"/>
        </span>
      </div>
    </div>
    <div v-if="showResultsList" class="results">
      <div v-if="paginatedSortedResults.length" class="results-exist">
        <div class="results-actions col-12 md-col-6 d-flex justify-content-between flex-wrap my-2">
          <div class="btn-group col-12" role="group" aria-label="Button group">
            <button class="results-actions-button btn btn-secondary" @click="toggleMovieTV">
              <i v-if="currentLogIsTVLog" class="bi bi-film"/>
              <i v-else class="bi bi-tv"/>
            </button>
            <button class="results-actions-button btn btn-warning" @click="shareResults">
              <span v-if="!sharing">
                <i class="bi bi-share"/>
              </span>
              <div v-else class="spinner-border text-light" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
            <button class="results-actions-button btn btn-danger" @click="gridLayout = !gridLayout">
              <i v-if="gridLayout" class="bi bi-view-list"/>
              <i v-else class="bi bi-grid-1x2"/>
            </button>
            <button class="results-actions-button filtered-count-display btn btn-secondary" @click="toggleCountViewsAverage">
              <span v-if="showAverage">
                <span class="average-label">(avg)</span>
                <span class="average-value">{{averageRating(filteredResults)}}</span>
              </span>
              <span v-else-if="showViewCount">
                <span class="average-label">(views)</span>
                <span class="average-value">{{viewsCount(filteredResults)}}</span>
              </span>
              <span v-else-if="activeQuickLinkList === 'bestPicture'">{{bestPicturesWithRatings.length}}/{{filteredResults.length}}</span>
              <span v-else>{{filteredResults.length}}</span>
            </button>
            <button v-if="!currentLogIsTVLog" class="results-actions-button btn btn-info collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#insights-accordion" aria-expanded="false" aria-controls="insights-accordion">
              <i class="bi bi-lightbulb"/>
            </button>
            <button class="results-actions-button btn btn-warning btn-sm" type="button" data-bs-toggle="collapse" data-bs-target="#quick-links-accordion" aria-expanded="false" aria-controls="quick-links-accordion">
              <i class="bi bi-lightning-charge"/>
            </button>
            <button class="results-actions-button btn btn-info btn-sm" @click="findRandomSearchValue">
              <i class="bi bi-shuffle"/>
            </button>
            <button class="results-actions-button btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i v-if="sortValue === 'rating'" class="bi bi-123"/>
              <i v-if="sortValue === 'watched'" class="bi bi-calendar3"/>
              <i v-if="sortValue === 'release'" class="bi bi-calendar-date"/>
              <i v-if="sortValue === 'title'" class="bi bi-alphabet"></i>
              <i v-if="sortValue === 'views'" class="bi bi-eye"></i>
              <i v-if="sortValue === 'direction'" class="bi bi-dpad"></i>
              <i v-if="sortValue === 'imagery'" class="bi bi-image"></i>
              <i v-if="sortValue === 'story'" class="bi bi-book"></i>
              <i v-if="sortValue === 'performance'" class="bi bi-speedometer"></i>
              <i v-if="sortValue === 'soundtrack'" class="bi bi-music-note-beamed"></i>
              <i v-if="sortValue === 'stickiness'" class="bi bi-sticky"></i>
              <span class="order-arrow">
                <i v-if="sortOrder !== 'ascending'" class="bi bi-arrow-down-short"/>
                <i v-if="sortOrder === 'ascending'" class="bi bi-arrow-up-short"/>
              </span>
              <ul class="dropdown-menu">
                <li value="rating">
                  <button class="dropdown-item" :class="{active: sortValue === 'rating'}" @click="setSortValue('rating')">
                    Rating
                  </button>
                </li>
                <li value="watched">
                  <button class="dropdown-item" :class="{active: sortValue === 'watched'}" @click="setSortValue('watched')">
                    Watch Date
                  </button>
                </li>
                <li value="release">
                  <button class="dropdown-item" :class="{active: sortValue === 'release'}" @click="setSortValue('release')">
                    Release Date
                  </button>
                </li>
                <li value="title">
                  <button class="dropdown-item" :class="{active: sortValue === 'title'}" @click="setSortValue('title')">
                    Title
                  </button>
                </li>
                <li value="views">
                  <button class="dropdown-item" :class="{active: sortValue === 'views'}" @click="setSortValue('views')">
                    Views
                  </button>
                </li>
                <li value="direction">
                  <button class="dropdown-item" :class="{active: sortValue === 'direction'}" @click="setSortValue('direction')">
                    Direction
                  </button>
                </li>
                <li value="imagery">
                  <button class="dropdown-item" :class="{active: sortValue === 'imagery'}" @click="setSortValue('imagery')">
                    Imagery
                  </button>
                </li>
                <li value="story">
                  <button class="dropdown-item" :class="{active: sortValue === 'story'}" @click="setSortValue('story')">
                    Story
                  </button>
                </li>
                <li value="performance">
                  <button class="dropdown-item" :class="{active: sortValue === 'performance'}" @click="setSortValue('performance')">
                    Performance
                  </button>
                </li>
                <li value="soundtrack">
                  <button class="dropdown-item" :class="{active: sortValue === 'soundtrack'}" @click="setSortValue('soundtrack')">
                    Soundtrack
                  </button>
                </li>
                <li value="stickiness">
                  <button class="dropdown-item" :class="{active: sortValue === 'stickiness'}" @click="setSortValue('stickiness')">
                    Stickiness
                  </button>
                </li>
              </ul>
            </button>
          </div>
          <div ref="quickLinkTypes" class="quick-link-types d-flex align-items-center flex-wrap col-md-12">
            <div id="quick-links-accordion" class="col-12 mt-1 accordion-collapse collapse">
              <div>
                <span
                  class="badge mx-1"
                  :class="activeQuickLinkList === 'annual' ? 'text-bg-success' : 'text-bg-secondary'"
                  @click="toggleAnnualBestFilter"
                >
                  Annual Best
                </span>
                <span
                  class="badge mx-1"
                  :class="activeQuickLinkList === 'bestPicture' ? 'text-bg-success' : 'text-bg-secondary'"
                  @click="toggleBestPicturesFilter"
                >
                  Best Picture
                </span>
                <span
                  class="badge mx-1"
                  :class="activeQuickLinkList === 'genre' ? 'text-bg-success' : 'text-bg-secondary'"
                  @click="toggleQuickLinksList('genre')"
                >
                  Genres
                </span>
                <span
                  class="badge mx-1"
                  :class="activeQuickLinkList === 'keyword' ? 'text-bg-success' : 'text-bg-secondary'"
                  @click="toggleQuickLinksList('keyword')"
                >
                  Keywords
                </span>
                <span
                  class="badge mx-1"
                  :class="activeQuickLinkList === 'year' ? 'text-bg-success' : 'text-bg-secondary'"
                  @click="toggleQuickLinksList('year')"
                >
                  Years
                </span>
                <span
                  class="badge mx-1"
                  :class="activeQuickLinkList === 'director' ? 'text-bg-success' : 'text-bg-secondary'"
                  @click="toggleQuickLinksList('director')"
                >
                  <span v-if="currentLogIsTVLog">Creators</span>
                  <span v-else>Directors</span>
                </span>
                <span
                  class="badge mx-1"
                  :class="activeQuickLinkList === 'cast/crew' ? 'text-bg-success' : 'text-bg-secondary'"
                  @click="toggleQuickLinksList('cast/crew')"
                >
                  Cast/Crew Members
                </span>
                <span
                  class="badge mx-1"
                  :class="activeQuickLinkList === 'studios' ? 'text-bg-success' : 'text-bg-secondary'"
                  @click="toggleQuickLinksList('studios')"
                >
                  Studios
                </span>
              </div>
              <div v-if="sortedDataForActiveQuickLinkList.length" class="quick-links-list-wrapper mt-2">
                <div class="accordion-body col-12">
                  <button
                    class="quick-links-list-sort"
                    :class="darkOrLight"
                    @click="toggleQuickLinksSort"
                  >
                    {{quickLinksSortType}}
                  </button>
                  <ul class="quick-link-list p-0 col-12">
                    <li v-for="(value, index) in sortedDataForActiveQuickLinkList" :key="index" @click="updateSearchValue(value.name)">
                      <span class="badge mx-1" :class="darkOrLight">
                        {{ value.name }}<span v-if="quickLinksSortType === 'count' && value.count">&nbsp;({{value.count}})</span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="insights-accordion" ref="insightsAccordion" class="accordion-collapse collapse col-12" aria-labelledby="insights">
            <div class="accordion-body col-12">
              <div class="details py-3">
                <p v-if="filteredResults.length === allEntriesWithFlatKeywordsAdded.length" class="fs-5 my-2 text-center">
                  You've rated {{allEntriesWithFlatKeywordsAdded.length}} {{movieOrTVShowDisplay}}s.
                </p>
                <p class="m-0 d-flex justify-content-center align-items-center">
                  They have an average rating of {{averageRating(filteredResults)}}
                </p>
              </div>
              <Charts
                v-if="!this.currentLogIsTVLog"
                :results="filteredResults"
                :allEntriesWithFlatKeywordsAdded="allEntriesWithFlatKeywordsAdded"
                :allCounts="allCounts"
                @updateSearchValue="updateSearchValue"
              />
            </div>
          </div>
        </div>
        <StickinessModal
          v-if="showStickinessModal"
          :showStickinessModal="showStickinessModal"
          :allEntriesWithFlatKeywordsAdded="allEntriesWithFlatKeywordsAdded"
        />
        <TweakModal
          v-else-if="showTweakModal"
          :showTweakModal="showTweakModal"
          :allEntriesWithFlatKeywordsAdded="allEntriesWithFlatKeywordsAdded"
        />
        <ul v-if="gridLayout" class="grid-layout pb-3" :class="listCountClasses">
          <DBGridLayoutSearchResult
            v-for="(result, index) in paginatedSortedResults"
            :key="topStructure(result).id"
            :result="result"
            :keywordCounts="allCounts.keywords"
            :index="index"
            :resultsAreFiltered="resultsAreFiltered"
            :sortValue="sortValue"
            :activeQuickLinkList="activeQuickLinkList"
            @updateSearchValue="updateSearchValue"
          />
        </ul>
        <ul v-else>
          <DBSearchResult
            v-for="(result, index) in paginatedSortedResults"
            :key="topStructure(result).id"
            :result="result"
            :index="index"
            :resultsAreFiltered="resultsAreFiltered"
            @updateSearchValue="updateSearchValue"
          />
        </ul>
        <button
          v-if="sortedResults.length > numberOfResultsToShow"
          class="btn btn-secondary mb-5 float-end"
          @click="addMoreResults"
        >
          More...
        </button>
        <div v-if="!(sortedResults.length > numberOfResultsToShow) && value" class="mb-5">
          <div v-if="noResults" ref="noResults">
            <p>No results found in your Movie Log or on TMDB.</p>
            <p>I'm pretty sure that movie doesn't exist.</p>
            <p>Either you're from the future or maybe you just spelled it wrong.</p>
          </div>
          <div v-else class="button-wrapper d-flex justify-content-end">
            <button class="btn btn-primary" @click="searchTMDB" id="new-rating-button">Search TMDB for {{titleCase(value)}}</button>
          </div>
        </div>
      </div>
      <div v-else class="no-results-but-search-type">
        <p class="text-center">No {{movieOrTVShowDisplay}}s found for your search.</p>
        <button class="btn btn-link col-12" @click="toggleQuickLinksList(null)">Clear quick filters?</button>
      </div>
    </div>
    <NoResults v-else-if="$store.state.dbLoaded" :value="value" @clearValue="clearValue"/>
    <div v-else class="d-flex justify-content-center align-items-center my-5">
      <div class="spinner-border text-light" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <InsetBrowserModal :show="showInsetBrowserModal" :url="insetBrowserUrl" @close="showInsetBrowserModal = false" />
  </div>
</template>

<script>
import axios from 'axios';
import uniq from 'lodash/uniq';
import minBy from 'lodash/minBy';
import debounce from 'lodash/debounce';
import Charts from "./Charts.vue";
import DBSearchResult from './DBSearchResult.vue';
import DBGridLayoutSearchResult from './DBGridLayoutSearchResult.vue';
import NewRatingSearch from "./NewRatingSearch.vue";
import StickinessModal from "./StickinessModal.vue";
import TweakModal from "./TweakModal.vue";
import NoResults from "./NoResults.vue";
import InsetBrowserModal from './InsetBrowserModal.vue';
import { getRating } from "../assets/javascript/GetRating.js";

export default {
  components: {
    Charts,
    DBSearchResult,
    DBGridLayoutSearchResult,
    NewRatingSearch,
    InsetBrowserModal,
    StickinessModal,
    TweakModal,
    NoResults
  },
  data () {
    return {
      sortOrder: "descending",
      value: "",
      activeQuickLinkList: "title",
      sortValue: null,
      quickLinksSortType: "count",
      numberOfResultsToShow: 54,
      sharing: false,
      noResults: false,
      gridLayout: true,
      hasCalledFindFilter: false,
      showInsetBrowserModal: false,
      insetBrowserUrl: "",
      showAverage: false,
      showViewCount: false
    }
  },
  watch: {
    DBSearchValue (newVal) {
      if (newVal || newVal === "") {
        this.value = newVal;
      }
    },
    DBSortValue (newVal) {
      if (newVal) {
        this.setSortValue(newVal)
      }
    },
    paginatedSortedResults (newVal, oldVal) {
      if (!oldVal.length && newVal.length) {
        this.checkResultsAndFindFilter();
      }
    },
    filteredResults: debounce(function (newVal) {
      this.$store.commit("setFilteredResults", newVal);
    }, 500),
  },
  mounted () {
    this.value = this.DBSearchValue;
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'instant'
    });

    if (this.$route.query.search) {
      this.value = decodeURIComponent(this.$route.query.search);
    } else {
      this.checkResultsAndFindFilter();
    }

    if (this.DBSortValue) {
      this.setSortValue(this.DBSortValue)
    } else {
      this.setSortValue("rating")
    }

    if (this.$route.query.movieDbKey) {
      this.$store.commit("setDBSortValue", "watched");
      this.sortOrder = "ascending";
    }
  },
  beforeRouteLeave () {
    this.sortOrder = "descending";
    this.setSortValue(null);
    this.value = "";
    this.$store.commit("setDBSearchValue", this.value);
    this.$store.commit("setDBSortValue", this.sortValue);
  },
  computed: {
    darkOrLight () {
      const inDarkMode = document.querySelector("body").classList.contains('bg-dark');

      return { 'text-bg-dark': inDarkMode, 'text-bg-light': !inDarkMode };
    },
    currentLogIsTVLog () {
      return this.$store.state.currentLog === "tvLog";
    },
    movieOrTVShowDisplay () {
      if (this.currentLogIsTVLog) {
        return "TV show";
      } else {
        return "movie";
      }
    },
    movieOrTV () {
      if (this.currentLogIsTVLog) {
        return "tv";
      } else {
        return "movie";
      }
    },
    DBSearchValue () {
      return this.$store.state.DBSearchValue;
    },
    DBSortValue () {
      return this.$store.state.DBSortValue;
    },
    allEntriesWithFlatKeywordsAdded () {
      return this.$store.getters.allMediaAsArray.map((result) => {
        if (this.currentLogIsTVLog) {
          return {
            ...result,
            tvShow: {
              ...this.topStructure(result),
              flatKeywords: this.topStructure(result).keywords ? this.topStructure(result).keywords.map((keyword) => keyword.name) : []
            }
          }
        } else {
          const flatTMDBKeywords = result.movie.keywords ? result.movie.keywords.map((keyword) => keyword.name) : [];
          const flatChatGPTKeywords = this.topStructure(result).chatGPTKeywords || [];
          const flatKeywords = uniq([...flatTMDBKeywords, ...flatChatGPTKeywords]);
          return {
            ...result,
            movie: {
              ...this.topStructure(result),
              flatKeywords: flatKeywords || []
            }
          }
        }
      });
    },
    resultsThatNeedStickiness () {
      return this.allEntriesWithFlatKeywordsAdded.filter((result) => {
        const hasntReratedStickinessOneWeek = !this.mostRecentRating(result).userAddedStickiness;
        const hasntReratedStickinessSixMonths = !this.mostRecentRating(result).userAddedSixMonthStickiness;
        const ratingDate = this.mostRecentRating(result).date || "1/1/2021";
        const moreThanAWeekAgo = new Date(ratingDate).getTime() < new Date().getTime() - (604800000);
        const moreThanSixMonthsAgo = new Date(ratingDate).getTime() < new Date().getTime() - (15778476000);

        return (hasntReratedStickinessOneWeek && moreThanAWeekAgo) || (hasntReratedStickinessSixMonths && moreThanSixMonthsAgo);
      }).sort((a, b) => {
        const ratingDateA = this.mostRecentRating(a).date || "1/1/2021";
        const ratingDateB = this.mostRecentRating(b).date || "1/1/2021";
        const dateA = new Date(ratingDateA);
        const dateB = new Date(ratingDateB);
        return dateB - dateA;
      });
    },
    showStickinessModal () {
      return Boolean(!this.currentLogIsTVLog && this.allEntriesWithFlatKeywordsAdded.length && this.resultsThatNeedStickiness.length);
    },
    showTweakModal () {
      if (this.showStickinessModal) {
        return false;
      }

      const firstTiedPairIndex = this.sortedByRating.findIndex((movie, index) => {
        const nextMovie = this.sortedByRating[index + 1];

        if (!nextMovie) {
          return false;
        }

        return getRating(movie).calculatedTotal === getRating(nextMovie).calculatedTotal;
      });

      if (firstTiedPairIndex === -1) {
        return false;
      }

      const hasTiedResults = Boolean(this.sortedByRating[firstTiedPairIndex] && this.sortedByRating[firstTiedPairIndex + 1]);
      const lastTweak = this.$store.state.settings.lastTweak || Date.now();
      const oneDay = 24 * 60 * 60 * 1000;
      const noTieBreakYetToday = Date.now() - lastTweak > oneDay;

      return !this.currentLogIsTVLog && hasTiedResults && noTieBreakYetToday;
    },
    filteredResults () {
      if (this.activeQuickLinkList === "annual") {
        this.$store.commit("setDBSortValue", "release");
        return this.bestMovieFromEachYear;
      } else if (this.activeQuickLinkList === "bestPicture") {
        this.$store.commit("setDBSortValue", "release");
        return this.bestPictures;
      } else if (this.value) {
        this.$store.commit("setDBSortValue", this.DBSortValue || "rating");
        return this.fuzzyFilter;
      } else {
        return this.allEntriesWithFlatKeywordsAdded;
      }
    },
    fuzzyFilter  () {
      return this.allEntriesWithFlatKeywordsAdded.filter((media) => {
        if (!this.value) {
          return true;
        }

        return this.topStructure(media).title.toLowerCase().includes(this.value.toLowerCase()) ||
        this.topStructure(media).flatKeywords?.includes(this.value.toLowerCase()) ||
        this.topStructure(media).genres?.find((genre) => genre.name.toLowerCase() === this.value.toLowerCase()) ||
        this.topStructure(media).cast?.flatMap((person) => {
          const names = person.name.toLowerCase().split(' ');
          return [person.name.toLowerCase(), ...names];
        }).includes(this.value.toLowerCase()) ||
        this.topStructure(media).crew?.flatMap((person) => {
          const names = person.name.toLowerCase().split(' ');
          return [person.name.toLowerCase(), ...names];
        }).includes(this.value.toLowerCase()) ||
        this.topStructure(media).production_companies?.map((company) => company.name.toLowerCase()).includes(this.value.toLowerCase()) ||
        (this.yearFilter.length && this.yearFilter.includes(`${this.getYear(media)}`));
      })
    },
    yearFilter () {
      let parsedYears = [];

      if (this.value.length === 2 && parseInt(this.value) < new Date().getFullYear() - 2000) {
        parsedYears = [`20${this.value}`];
      } else if (this.value.length === 2) {
        parsedYears = [`19${this.value}`];
      } else if (this.value.includes("-") && this.value.includes(" ")) {
        parsedYears = this.value.split(" ").join("").split("-");

        for (let i = parseInt(parsedYears[0]) + 1; i < parseInt(parsedYears[1]); i++) {
          parsedYears.push(i.toString());
        }
      } else if (this.value.includes("-")) {
        parsedYears = this.value.split("-");

        for (let i = parseInt(parsedYears[0]) + 1; i < parseInt(parsedYears[1]); i++) {
          parsedYears.push(i.toString());
        }
      } else if (this.value.length === 5 && this.value.includes("s")) {
        parsedYears = this.value.split("s").filter((x) => x);

        parsedYears.push(`${parseInt(parsedYears[0]) + 1}`);
        parsedYears.push(`${parseInt(parsedYears[0]) + 2}`);
        parsedYears.push(`${parseInt(parsedYears[0]) + 3}`);
        parsedYears.push(`${parseInt(parsedYears[0]) + 4}`);
        parsedYears.push(`${parseInt(parsedYears[0]) + 5}`);
        parsedYears.push(`${parseInt(parsedYears[0]) + 6}`);
        parsedYears.push(`${parseInt(parsedYears[0]) + 7}`);
        parsedYears.push(`${parseInt(parsedYears[0]) + 8}`);
        parsedYears.push(`${parseInt(parsedYears[0]) + 9}`);
      } else if (this.value.length === 3 && this.value.includes("s")) {
        parsedYears = this.value.split("s").filter((x) => x);

        if (parseInt(this.value) < new Date().getFullYear() - 2000) {
          parsedYears[0] = `20${parsedYears[0]}`;
        } else {
          parsedYears[0] = `19${parsedYears[0]}`;
        }
        parsedYears.push(`${parseInt(parsedYears[0]) + 1}`);
        parsedYears.push(`${parseInt(parsedYears[0]) + 2}`);
        parsedYears.push(`${parseInt(parsedYears[0]) + 3}`);
        parsedYears.push(`${parseInt(parsedYears[0]) + 4}`);
        parsedYears.push(`${parseInt(parsedYears[0]) + 5}`);
        parsedYears.push(`${parseInt(parsedYears[0]) + 6}`);
        parsedYears.push(`${parseInt(parsedYears[0]) + 7}`);
        parsedYears.push(`${parseInt(parsedYears[0]) + 8}`);
        parsedYears.push(`${parseInt(parsedYears[0]) + 9}`);
      } else if (this.value.length === 4 && parseInt(this.value)) {
        parsedYears = [this.value];
      }

      return parsedYears;
    },
    bestMovieFromEachYear () {
      const years = {};

      this.allEntriesWithFlatKeywordsAdded.forEach((result) => {
        let year;

        if (this.currentLogIsTVLog) {
          year = new Date(result.tvShow.first_air_date).getFullYear();
        } else {
          year = new Date(result.movie.release_date).getFullYear();
        }

        if (!years[year]) {
          years[year] = result;
        } else if (this.mostRecentRating(result).calculatedTotal > this.mostRecentRating(years[year]).calculatedTotal) {
          years[year] = result;
        }
      })

      return Object.keys(years).map((year) => years[year]);
    },
    bestPictures () {
      if (this.currentLogIsTVLog) {
        return [];
      }

      const bestPictureWinners = this.$store.state.academyAwardWinners.bestPicture;

      const allEntryIds = this.allEntriesWithFlatKeywordsAdded.map((result) => {
        return this.topStructure(result).id;
      });

      const bestPictureWinnersWithRatingStatus = [];

      bestPictureWinners.forEach((movie) => {
        if (allEntryIds.includes(movie.id)) {
          bestPictureWinnersWithRatingStatus.push({
            ...this.allEntriesWithFlatKeywordsAdded.find((entry) => entry.movie.id === movie.id),
            ...{
              movie: {
                ...this.allEntriesWithFlatKeywordsAdded.find((entry) => entry.movie.id === movie.id).movie,
                academyAwardsYear: movie.academyAwardsYear
              }
            }
          });
        } else {
          bestPictureWinnersWithRatingStatus.push({
            falseEntry: true,
            movie: movie,
            ratings: [
              {
                date: Date.now(),
                direction: 0,
                id: movie.id,
                imagery: 0,
                impression: 0,
                love: 0,
                medium: "",
                overall: 0,
                performance: 0,
                soundtrack: 0,
                stickiness: 0,
                story: 0,
                title: movie.title,
                year: new Date(movie.release_date).getFullYear()
              }
            ]
          });
        }
      });

      return bestPictureWinnersWithRatingStatus;
    },
    bestPicturesWithRatings () {
      return this.bestPictures.filter((result) => !result.falseEntry);
    },
    showResultsList () {
      return Boolean(this.paginatedSortedResults.length) || this.activeQuickLinkList !== "title";
    },
    sortedResults () {
      return [...this.filteredResults].sort(this.sortResults);
    },
    sortedByRating () {
      const allMediaSortedByRating = this.$store.getters.allMediaSortedByRating;

      if (this.sortOrder === 'ascending') {
        return allMediaSortedByRating;
      } else {
        return allMediaSortedByRating.slice().reverse();
      }
    },
    paginatedSortedResults () {
      return this.sortedResults.slice(0, this.numberOfResultsToShow);
    },
    datalistForActiveQuickLink () {
      if (this.activeQuickLinkList === "keyword") {
        return this.allKeywords;
      } else if (this.activeQuickLinkList === "genre") {
        return this.allGenres;
      } else if (this.activeQuickLinkList === "year") {
        return this.allYears;
      } else if (this.activeQuickLinkList === "director") {
        return this.allDirectors;
      } else if (this.activeQuickLinkList === "cast/crew") {
        return this.allCastCrew;
      } else if (this.activeQuickLinkList === "studios") {
        return this.allStudios;
      } else if (this.activeQuickLinkList === "mediums") {
        return this.allMediums;
      } else {
        return [];
      }
    },
    sortedDataForActiveQuickLinkList () {
      const data = [...this.datalistForActiveQuickLink];

      if (this.quickLinksSortType === "a-z") {
        return data.sort((a, b) => a.name.localeCompare(b.name));
      } else {
        return data.sort((a, b) => b.count - a.count);
      }
    },
    allTitles () {
      if (this.currentLogIsTVLog) {
        return this.sortedResults.map((result) => result.tvShow.name);
      } else {
        return this.sortedResults.map((result) => result.movie.title);
      }
    },
    filteredTitles () {
      if (this.currentLogIsTVLog) {
        return this.filteredResults.map((result) => result.tvShow.name);
      } else {
        return this.filteredResults.map((result) => result.movie.title);
      }
    },
    allKeywords () {
      return Object.keys(this.countedKeywords).map((keyword) => {
        return {
          name: this.titleCase(keyword),
          count: this.countedKeywords[keyword]
        }
      });
    },
    allGenres () {
      return Object.keys(this.countedGenres).map((keyword) => {
        return {
          name: this.titleCase(keyword),
          count: this.countedGenres[keyword]
        }
      });
    },
    allYears () {
      return Object.keys(this.countedYears).map((keyword) => {
        return {
          name: this.titleCase(keyword),
          count: this.countedYears[keyword]
        }
      });
    },
    allDirectors () {
      if (this.currentLogIsTVLog) {
        return [];
      }

      return Object.keys(this.countDirectors).map((keyword) => {
        const filmography = this.allEntriesWithFlatKeywordsAdded.find((entry) => {
          return entry.movie.crew.find((person) => person.job === "Director" && person.name === keyword);
        }).movie.crew.find((person) => person.name === keyword && person.filmography)?.filmography;

        return {
          name: this.titleCase(keyword),
          count: this.countDirectors[keyword],
          filmography: filmography ? filmography.filter((film) => new Date(film.release_date) < new Date() && film.popularity > 8.65) : []
        }
      });
    },
    allCastCrew () {
      return Object.keys(this.countCastCrew).map((keyword) => {
        return {
          name: this.titleCase(keyword),
          count: this.countCastCrew[keyword]
        }
      });
    },
    allStudios () {
      return Object.keys(this.countStudios).map((keyword) => {
        return {
          name: this.titleCase(keyword),
          count: this.countStudios[keyword]
        }
      });
    },
    allMediums () {
      const mediums = {};

      this.allEntriesWithFlatKeywordsAdded.forEach((result) => {
        if (!this.currentLogIsTVLog) {
          result.ratings.forEach((rating) => {
            if (!rating.medium) {
              return;
            } else if (mediums[rating.medium]) {
              mediums[rating.medium]++;
            } else {
              mediums[rating.medium] = 1;
            }
          })
        }
      })

      return Object.keys(mediums).map((medium) => {
        return {
          name: this.titleCase(medium),
          count: mediums[medium]
        }
      });
    },
    allCounts () {
      return {
        keywords: this.countedKeywords,
        genres: this.countedGenres,
        years: this.countedYears,
        directors: this.countDirectors,
        castCrew: this.countCastCrew,
        studios: this.countStudios,
        mediums: this.allMediums,
        filmographies: this.allDirectors
      }
    },
    countedKeywords () {
      const counts = {};

      this.allEntriesWithFlatKeywordsAdded.forEach((result) => {
        if (this.topStructure(result).flatKeywords) {
          this.topStructure(result).flatKeywords.forEach((keyword) => {
            if (counts[keyword]) {
              counts[keyword]++;
            } else if (keyword) {
              counts[keyword] = 1;
            }
          })
        }
      })

      return counts;
    },
    countedGenres () {
      const counts = {};

      this.allEntriesWithFlatKeywordsAdded.forEach((result) => {
        if (this.topStructure(result).genres) {
          this.topStructure(result).genres.forEach((genre) => {
            if (counts[genre.name]) {
              counts[genre.name]++;
            } else if (genre.name) {
              counts[genre.name] = 1;
            }
          })
        }
      })

      return counts;
    },
    countedYears () {
      const counts = {};

      this.allEntriesWithFlatKeywordsAdded.forEach((result) => {
        const year = this.getYear(result);
        if (counts[year]) {
          counts[year]++;
        } else if (year) {
          counts[year] = 1;
        }
      })

      return counts;
    },
    countDirectors () {
      const counts = {};

      this.allEntriesWithFlatKeywordsAdded.forEach((result) => {
        let director;
        if (this.currentLogIsTVLog) {
          director = result.tvShow.created_by?.[0].name;
        } else {
          director = result.movie.crew?.find((person) => person.job === "Director").name;
        }

        if (director) {
          if (counts[director]) {
            counts[director]++;
          } else if (director) {
            counts[director] = 1;
          }
        }
      })

      return counts;
    },
    countCastCrew () {
      const counts = {};

      this.allEntriesWithFlatKeywordsAdded.forEach((result) => {
        const cast = this.topStructure(result).cast?.filter((person, index) => index < 10).map(person => person.name) || [];
        const crew = this.topStructure(result).crew?.filter((person, index) => index < 10).map(person => person.name) || [];
        const castCrewCombined = uniq([...cast, ...crew]);

        castCrewCombined.forEach((person) => {
          if (counts[person]) {
            counts[person]++;
          } else if (person) {
            counts[person] = 1;
          }
        })
      })

      return counts;
    },
    countStudios () {
      const counts = {};

      this.allEntriesWithFlatKeywordsAdded.forEach((result) => {
        const productionCompanies = this.topStructure(result).production_companies?.map(company => company.name) || [];

        productionCompanies.forEach((company) => {
          if (counts[company]) {
            counts[company]++;
          } else if (company) {
            counts[company] = 1;
          }
        })
      })

      return counts;
    },
    countMediums () {
      const counts = {};

      if (this.currentLogIsTVLog) {
        return counts;
      }

      this.allEntriesWithFlatKeywordsAdded.forEach((result) => {
        result.ratings.forEach((rating) => {
          if (counts[rating.medium]) {
            counts[rating.medium]++;
          } else if (rating.medium) {
            counts[rating.medium] = 1;
          }
        })
      })

      return counts;
    },
    resultsAreFiltered () {
      return Boolean(this.value);
    },
    placeholder () {
      if (this.activeQuickLinkList === 'annual') {
        return "The best of each year";
      } else if (this.activeQuickLinkList === 'bestPicture') {
        return "The Best Picture winners";
      } else {
        return "Search...";
      }
    },
    listCountClasses () {
      const count = this.paginatedSortedResults.length;

      return {
        "count-is-1": count === 1,
        "count-is-2": count === 2,
        "count-is-3": count === 3,
        "count-is-4": count === 4,
        "count-more-than-4-remainder-0": count > 4 & count % 4 === 0,
        "count-more-than-4-remainder-1": count > 4 & count % 4 === 1,
        "count-more-than-4-remainder-2": count > 4 & count % 4 === 2,
        "count-more-than-4-remainder-3": count > 4 & count % 4 === 3
      }
    }
  },
  methods: {
    toTitleCase (str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    toggleMovieTV () {
      this.clearValue();
      this.$store.dispatch('toggleCurrentLog');
    },
    checkResultsAndFindFilter () {
      const allowRandom = !this.$route.query.noRandom;
      const hasResults = this.paginatedSortedResults.length > 0;
      const hasNotCalledFindFilter = !this.hasCalledFindFilter;
      const isNotInTVMode = !this.currentLogIsTVLog;

      if (allowRandom && hasResults && hasNotCalledFindFilter && isNotInTVMode) {
        this.findRandomSearchValue();
        this.hasCalledFindFilter = true;
      }
    },
    sanitizeId (id) {
      id = id || crypto.randomUUID();

      return `movie-${id.replace(/[^a-z0-9\-_:.]/gi, '_')}`;
    },
    findRandomSearchValue () {
      this.$router.push({ query: { ...this.$route.query, returnFromRating: undefined } });
      const countedLists = ["keyword", "genre", "year", "director", "cast/crew", "studios"];
      const randomList = countedLists[Math.floor(Math.random() * countedLists.length)];

      const minimumCount = 3;

      let counts;
      switch (randomList) {
        case "keyword":
          counts = this.countedKeywords;
          break;
        case "genre":
          counts = this.countedGenres;
          break;
        case "year":
          counts = this.countedYears;
          break;
        case "director":
          counts = this.countDirectors;
          break;
        case "cast/crew":
          counts = this.countCastCrew;
          break;
        case "studios":
          counts = this.countStudios;
          break;
      }

      const valuesFromRandomCountedList = Object.keys(counts);
      let randomValue;
      let safetyLimit = 100;

      do {
        const randomIndex = Math.floor(Math.random() * valuesFromRandomCountedList.length);
        randomValue = valuesFromRandomCountedList[randomIndex];
        safetyLimit--;
      } while (counts[randomValue] <= minimumCount && safetyLimit > 0);

      if (randomValue && safetyLimit > 0) {
        this.updateSearchValue(randomValue);
      } else {
        this.clearValue();
      }
    },
    async wikiLinkFor (searchValue) {
      const wiki = await axios.get(`https://en.wikipedia.org/w/api.php?action=query&origin=*&format=json&generator=search&gsrnamespace=0&gsrlimit=5&gsrsearch=%27${searchValue}%27`);
      const pages = wiki.data.query.pages;
      const pagesArray = Object.keys(pages).map((page) => pages[page]);
      const bestMatch = minBy(pagesArray, (page) => page.index);

      return `https://en.m.wikipedia.org/w/index.php?curid=${bestMatch.pageid}`;
    },
    async goToWikipedia () {
      this.insetBrowserUrl = await this.wikiLinkFor(this.value);
      this.showInsetBrowserModal = true;
    },
    async getDirectorsFilmography (director) {
      const filmography = await axios.get(`https://api.themoviedb.org/3/person/${director.id}/movie_credits?api_key=${process.env.VUE_APP_TMDB_API_KEY}`);
      const directingCredits = filmography.data.crew.filter((credit) => credit.job === "Director");

      const minimizedCredits = directingCredits.map((credit) => {
        return {
          id: credit.id,
          popularity: credit.popularity,
          release_date: credit.release_date,
          title: credit.title
        }
      });

      return minimizedCredits;
    },
    clearValue () {
      this.value = "";
    },
    updateSearchValue (value) {
      this.value = value;

      this.$refs.insightsAccordion?.classList.remove("show");
    },
    toggleQuickLinksSort () {
      if (this.quickLinksSortType === "a-z") {
        this.quickLinksSortType = "count";
      } else {
        this.quickLinksSortType = "a-z";
      }
    },
    toggleQuickLinksList (name) {
      if (this.activeQuickLinkList === name || !name) {
        this.activeQuickLinkList = "title";

        this.$refs.QuickLinksAccordion?.classList.remove("show");
      } else {
        this.activeQuickLinkList = name;

        this.$refs.QuickLinksAccordion?.classList.add("show");
      }
    },
    toggleAnnualBestFilter () {
      if (this.activeQuickLinkList === "annual") {
        this.activeQuickLinkList = "title";
      } else {
        this.activeQuickLinkList = "annual";
      }
    },
    toggleBestPicturesFilter () {
      if (this.activeQuickLinkList === "bestPicture") {
        this.activeQuickLinkList = "title";
      } else {
        this.activeQuickLinkList = "bestPicture";
      }
    },
    inputValueFilter (results) {
      return results.filter((media) => {
        if (this.currentLogIsTVLog) {
          return this.topStructure(media).name.toLowerCase().includes(this.value.toLowerCase());
        } else {
          return this.topStructure(media).title.toLowerCase().includes(this.value.toLowerCase());
        }
      })
    },
    toggleSortOrder () {
      if (this.sortOrder === "ascending") {
        this.sortOrder = "descending";
      } else {
        this.sortOrder = "ascending";
      }
    },
    setSortValue (value) {
      if (this.sortValue === value) {
        this.toggleSortOrder();
      } else {
        this.sortValue = value;
      }
    },
    getSortValue (item, key) {
      if (key === "rating") {
        return this.mostRecentRating(item).calculatedTotal;
      } else if (key === "release") {
        return new Date(this.currentLogIsTVLog ? item.tvShow.first_air_date : item.movie.release_date);
      } else if (key === "title") {
        return this.currentLogIsTVLog ? item.tvShow.name : item.movie.title;
      } else if (key === "watched") {
        const date = this.mostRecentRating(item).date || "3/22/1982";
        return new Date(date);
      } else if (key === "views") {
        return item.ratings.length;
      } else {
        const keyScore = parseInt(this.mostRecentRating(item)[key]);
        const keysToCompare = ["direction", "imagery", "impression", "love", "performance", "soundtrack", "stickiness", "story"];
        const isKeyScoreHighestScore = keysToCompare.some((keyToCompare) => {
          const keyToCompareScore = parseInt(this.mostRecentRating(item)[keyToCompare]);
          return keyToCompareScore >= keyScore;
        });
        return isKeyScoreHighestScore ? keyScore : 0;
      }
    },
    sortResults (a, b) {
      const sortValueA = this.getSortValue(a, this.sortValue || "rating");
      const sortValueB = this.getSortValue(b, this.sortValue || "rating");

      if (sortValueA === sortValueB) {
        const secondarySortValueA = this.mostRecentRating(a).calculatedTotal;
        const secondarySortValueB = this.mostRecentRating(b).calculatedTotal;

        if (secondarySortValueA < secondarySortValueB) {
          return this.sortOrder === "ascending" ? 1 : -1;
        }
        if (secondarySortValueA > secondarySortValueB) {
          return this.sortOrder === "ascending" ? -1 : 1;
        }
        return 0;
      }

      if (sortValueA < sortValueB) {
        return this.sortOrder === "ascending" ? 1 : -1;
      }
      if (sortValueA > sortValueB) {
        return this.sortOrder === "ascending" ? -1 : 1;
      }

      return 0;
    },
    toggleCountViewsAverage () {
      if (this.showAverage) {
        this.showAverage = false;
        this.showViewCount = true;
      } else if (this.showViewCount) {
        this.showAverage = false;
        this.showViewCount = false;
      } else {
        this.showAverage = true;
        this.showViewCount = false;
      }
    },
    averageRating (results) {
      const ratedMovies = results.filter((result) => this.mostRecentRating(result).calculatedTotal);
      const ratings = ratedMovies.map((result) => parseFloat(this.mostRecentRating(result).calculatedTotal));
      const total = ratings.reduce((a, b) => a + b, 0);
      return (total / ratings.length).toFixed(2);
    },
    viewsCount (results) {
      return results.reduce((total, result) => {
        return total + (result.ratings ? result.ratings.length : 0);
      }, 0);
    },
    getYear (media) {
      let date;
      if (this.currentLogIsTVLog) {
        date = media.tvShow.first_air_date;
      } else {
        date = media.movie.release_date;
      }

      return new Date(date).getFullYear();
    },
    mostRecentRating (media) {
      return getRating(media);
    },
    async searchTMDB () {
      if (!this.value) {
        return;
      }

      const resp = await axios.get(`https://api.themoviedb.org/3/search/${this.movieOrTV}?api_key=${process.env.VUE_APP_TMDB_API_KEY}&language=en-US&query=${this.value}`);

      if (resp.data.results.length) {
        this.newEntrySearch(resp.data.results);
      } else {
        this.showNoResultsMessage();
      }
    },
    newEntrySearch (results) {
      this.$store.commit('setNewEntrySearchResults', results)

      this.$router.push(`/pick-media/${this.value}`);
    },
    showNoResultsMessage () {
      this.noResults = true;

      setTimeout(() => {
        this.noResults = false;
        this.clearValue();
      }, 3000);
    },
    addMoreResults () {
      this.numberOfResultsToShow = this.numberOfResultsToShow + 50;

      this.$nextTick(() => {
        window.scrollBy({
          top: 500,
          behavior: 'smooth'
        })
      });
    },
    async shareResults () {
      this.sharing = true;

      const shareObject = {
        results: this.sortedResults,
        sortOrder: this.sortOrder,
        sortValue: this.sortValue,
        value: this.value
      };

      const dbKey = `${new Date().getTime()}-${crypto.randomUUID()}`;

      const dbEntry = {
        path: `sharedDBSearches/${dbKey}`,
        value: shareObject
      }

      this.$store.dispatch('setDBValue', dbEntry);

      this.sharing = false;
      this.value = "";

      this.$nextTick(() => {
        this.$router.push(`/share/${this.$store.state.databaseTopKey}/${dbKey}`);
      });
    },
    topStructure (result) {
      if (this.currentLogIsTVLog) {
        return result.tvShow;
      } else {
        return result.movie;
      }
    },
    titleCase (input) {
      const string = input.toString();
      return string.replace(
        /\w\S*/g,
        function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      );
    },
    increaseFontSize (event) {
      event.target.classList.add('font-size-increased');
      event.target.style.fontSize = '16px';
      // event.target.style.margin = '-6px 0 0';
    },
    decreaseFontSize (event) {
      event.target.classList.remove('font-size-increased');
      event.target.style.fontSize = '12px';
      // event.target.style.margin = '0';
    },
  },
}
</script>

<style lang="scss">
  .home {
    max-width: 832px;

    .search-bar {
      max-width: 416px;

      input#search {
        border-bottom-right-radius: .375rem;
        border-top-right-radius: .375rem;

        &.has-content {
          padding-left: 36px;
          padding-right: 36px;
        }

        &.font-size-increased {
          margin: -6px 0 0;

          & + .clear-button {
            transform: translateY(calc(-50% - 3px));
          }

          & + .clear-button + .more-info-button {
            transform: translateY(calc(-50% - 3px));
          }
        }
      }

      .clear-button {
        align-items: center;
        color: black;
        cursor: pointer;
        display: flex;
        height: 60px;
        justify-content: center;
        left: 0px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 40px;
        z-index: 5;
      }

      .more-info-button {
        align-items: center;
        color: black;
        cursor: pointer;
        display: flex;
        height: 40px;
        justify-content: center;
        right: 0px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 40px;
        z-index: 5;
      }

      svg {
        height: 18px;
        width: 18px;
      }
    }

    .results {
      .results-count {
        font-size: 1rem;
      }

      .results-actions {
        .dropdown-item {
          &.active,
          &:active {
            color: #1e2125;
            background-color: #e9ecef;
          }
        }

        .dropdown-toggle {
          position: relative;

          &::after {
            display: none;
          }

          > i {
            margin-right: 6px;
          }

          .order-arrow {
            position: absolute;
            right: 7px;
            top: 49%;
            transform: translateY(-49%);
          }
        }

        button {
          svg {
            height: 14px;
            width: 14px;
          }

          border: none;

          &.results-actions-button {
            &:nth-child(1) {
              background-color: #E71D36; /* Red */
              color: white;
            }

            &:nth-child(2) {
              background-color: #FF9F1C; /* Orange */
              color: black;
            }

            &:nth-child(3) {
              background-color: #FFD700; /* Yellow */
              color: black;
            }

            &:nth-child(4) {
              background-color: #2EC4B6; /* Green */
              color: black;
            }

            &:nth-child(5) {
              background-color: #00FFFF; /* Cyan */
              color: black;
            }

            &:nth-child(6) {
              background-color: #1D8BF1; /* Medium Blue */
              color: white;
            }

            &:nth-child(7) {
              background-color: #5A189A; /* Indigo */
              color: white;
            }

            &:nth-child(8) {
              background-color: #9D4EDD; /* Violet */
              color: white;
            }
          }
        }

        .filtered-count-display {
          .average-label {
            font-size: 0.5rem;
            position: absolute;
            bottom: 4px;
            left: 50%;
            transform: translateX(-50%);
          }

          .average-value {
            position: relative;
            top: -4px;
          }
        }

        .quick-link-types {
          row-gap: 6px;

          span {
            cursor: pointer;
            font-size: 0.75rem;
          }

          .quick-links-list-wrapper {
            border: 1px solid #c0c2c3;
            max-height: 150px;
            overflow-y: scroll;
            position: relative;

            .quick-links-list-sort {
              position: sticky;
              top: 4px;
              left: 100%;
              border: 1px solid #c0c2c3;
              border-bottom-left-radius: 2px;
              padding: 2px 4px;
              background: white;
              font-size: 0.65rem;
              margin: 4px 8px;
            }

            .quick-link-list {
              column-count: 2;
              column-gap: 0;
              list-style: none;
              margin-top: -20px;

              li {
                .badge {
                  align-items: center;
                  cursor: pointer;
                  display: flex;
                  text-align: start;
                  white-space: break-spaces;

                  span {
                    font-size: 0.5rem;
                  }
                }
              }
            }
          }
        }
      }

      ul {
        list-style: none;
        width: 100%;
        padding: 0;
        margin: 0;

        &.grid-layout {
          display: grid;
          grid-gap: 0;

          li {
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

            &.highlight {
              border: 2px solid #54b448;
              transform: scale(1.2);
              z-index: 1;
            }

            img {
              width: 100%;
              height: auto;
              object-fit: cover;
            }
          }

          &.count-is-1 {
            grid-template-columns: repeat(4, 1fr);

            li {
              grid-column: span 4;
              grid-row: span 2;

              @media screen and (min-width: 832px) {
                grid-column: 2 / span 2;
                grid-row: span 2;
              }
            }
          }

          &.count-is-2 {
            grid-template-columns: repeat(4, 1fr);

            li {
              grid-column: span 2;
              grid-row: span 2;
            }
          }

          &.count-is-3 {
            grid-template-columns: repeat(4, 1fr);

            @media screen and (min-width: 832px) {
              grid-template-columns: repeat(3, 1fr);
            }

            li {
              &:first-child {
                grid-column: span 4;
                grid-row: span 2;
              }

              &:nth-child(n+1) {
                grid-column-end: span 2;
              }

              @media screen and (min-width: 832px) {
                /* Override the first-child settings for larger screens */
                &:first-child {
                  grid-column: span 1; /* Corrected to span only 1 column */
                }
                /* Ensure each li takes up 1/3 of the width */
                &:nth-child(n+1) {
                  grid-column-end: span 1;
                }
              }
            }
          }

          &.count-is-4 {
            grid-template-columns: repeat(12, 1fr);

            li {
              &:first-child {
                grid-column: span 12;
                grid-row: span 12;
              }

              &:nth-child(2),
              &:nth-child(3),
              &:nth-child(4) {
                grid-column: span 4;
                grid-row: span 4;
              }

              @media screen and (min-width: 832px) {
                &:first-child,
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                  grid-column: span 3;
                  grid-row: span 4;
                }
              }
            }
          }

          &.count-more-than-4-remainder-0 {
            grid-template-columns: repeat(12, 1fr);

            li {
              &:first-child {
                grid-column: span 12;
                grid-row: span 12;
              }

              &:nth-child(2),
              &:nth-child(3),
              &:nth-child(4) {
                grid-column: span 4;
                grid-row: span 4;
              }

              &:nth-child(n+5) {
                grid-column-end: span 3;
              }

              @media screen and (min-width: 832px) {
                &:first-child,
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(n+5) {
                  grid-column: span 3;
                  grid-row: span 4;
                }
              }
            }
          }

          &.count-more-than-4-remainder-1 {
            grid-template-columns: repeat(4, 1fr);

            li {
              &:first-child {
                grid-column: span 2;
                grid-row: span 2;
              }

              &:nth-child(n+2) {
                grid-column: span 1;
                grid-row: span 1;
              }
            }
          }

          &.count-more-than-4-remainder-2 {
            grid-template-columns: repeat(4, 1fr);

            li {
              &:first-child {
                grid-column: span 2;
                grid-row: span 2;
              }

              &:nth-child(2) {
                grid-column: span 2;
                grid-row: span 2;
              }

              &:nth-child(n+2) {
                grid-column-end: span 1;
              }
            }
          }

          &.count-more-than-4-remainder-3 {
            grid-template-columns: repeat(4, 1fr);

            @media screen and (min-width: 832px) {
              grid-template-columns: repeat(12, 1fr);
            }

            li {
              &:first-child {
                grid-column: span 4;
                grid-row: span 4;
              }

              &:nth-child(2),
              &:nth-child(3) {
                grid-column: span 2;
                grid-row: span 2;
              }

              &:nth-child(n+4) {
                grid-column: span 1;
                grid-row: span 2;
              }

              @media screen and (min-width: 832px) {
                &:first-child,
                &:nth-child(2),
                &:nth-child(3) {
                  grid-column: span 4;
                  grid-row: span 2;
                }

                &:nth-child(n+4) {
                  grid-column: span 3;
                  grid-row: span 2;
                }
              }
            }
          }

          .cinemaroll-modal-content {
            max-height: 97vh !important;
          }
        }
      }
    }

    .btn {
      .spinner-border {
        height: 18px;
        width: 18px;
      }
    }

    .new-rating {
      a {
        cursor: pointer;
      }
    }
  }

  .bg-dark {
    .home {
      color: white;

      ul {
        .media-result {
          border-color: white;
        }
      }
    }
  }
</style>